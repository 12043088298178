import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  margin-bottom: 3rem;
  margin-top: 1rem;
  align-items: center;
  justify-items: center;
  gap: 5%;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 14px;
  padding: 2rem;
  @media (min-width: 0px) {
  }
  @media (min-width: 550px) {
    ${(props) =>
      props.iconOn &&
      `
    grid-template-columns: repeat(2, 1fr)
    `};
  }
  @media (min-width: 1100px) {
  }
`;
export const TextBox = styled.div``;
export const Title = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
`;
export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  height: 100%;
  width: 100%;
  font-size: 6rem;

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row: 1/3;
    font-size: 4rem;
  }
`;

export const P = styled.p`
  margin: 0;
  text-align: justify;
  margin-bottom: 1rem;
`;

export const Icon = styled.i`
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
`;
