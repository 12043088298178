import { NavContainerStyled, NavLinkStyled } from "./elements";
import UseLocalStorage from "../../functions/use-local-storage";
import {
  FcHome,
  FcGraduationCap,
  FcPositiveDynamic,
  FcInfo
} from "../../assets/icons";
export const Navbar = ({ pageCall, setPageCall }) => {
  const [navIndex, setNavIndex] = UseLocalStorage("navIndex", 0);
  const navElements = [
    { title: "welcome", path: "/", icon: <FcHome /> },
    { title: "experience", path: "/experience", icon: <FcGraduationCap /> },
    { title: "skills", path: "/skills", icon: <FcPositiveDynamic /> },
    { title: "contact", path: "/contact", icon: <FcInfo /> }
  ];

  return (
    <NavContainerStyled>
      {navElements.map((e, i) => {
        return (
          <NavLinkStyled
            key={i}
            active={navIndex + 1}
            onClick={() => {
              setNavIndex(i);
              setPageCall((pageCall) => ({
                ...pageCall,
                pageName:
                  window.location.pathname.slice(1) !== ""
                    ? window.location.pathname.slice(1)
                    : "welcome",
                success: false
              }));
            }}
            to={e.path}
          >
            <span> {e.icon} </span>
          </NavLinkStyled>
        );
      })}
    </NavContainerStyled>
  );
};
