import { useEffect, useState } from "react";
import { Outer } from "../elements";
import { ImageAndTitle } from "../components/welcome/image-and-title";
import { SpokenLanguages } from "../components/welcome/spoken-languages";
import { Loading } from "../components/loading";
import { MediaBox } from "../components/media-box";

export const Welcome = ({ pageCall }) => {
  const [data, setData] = useState({});
  const { name, title, english, german, arabic, about, image } = data;
  useEffect(() => {
    const { Omar, English, German, Arabic, About, image } = pageCall.resp;
    const passed = Omar && English && German && Arabic && About && image;
    if (passed) {
      setData({
        image: image[1],
        name: Omar[0],
        title: Omar[1],
        english: English,
        german: German,
        arabic: Arabic,
        about: About,
        success: true
      });
    }
  }, [pageCall.success]);

  return (
    <Outer>
      {data.success ? (
        <>
          <ImageAndTitle img={image} name={name} title={title} />
          <SpokenLanguages english={english} german={german} arabic={arabic} />
          <MediaBox iconOn={false} title={"about me"} paragraph={about} />
        </>
      ) : (
        <Loading />
      )}
    </Outer>
  );
};
