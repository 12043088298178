import "./styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getPage } from "./functions/get-page";
import { useEffect, useState } from "react";
import { Navbar } from "./components/navbar";
import { Welcome } from "./pages/welcome";
import { Experience } from "./pages/experience";
import { Skills } from "./pages/skills";
import { Contact } from "./pages/contact";
import { url } from "./config";

export default function App() {
  const [pageCall, setPageCall] = useState({
    loading: false,
    trigger: false,
    success: false,
    error: false,
    pageName: "welcome",
    resp: {}
  });

  getPage(pageCall, setPageCall, `${url}/page`);

  const allRoutes = [
    { path: "/", component: Welcome },
    { path: "/experience", component: Experience },
    { path: "/skills", component: Skills },
    { path: "/contact", component: Contact }
  ];

  const [currentPath, setCurrentPath] = useState(
    window.location.pathname.slice(1) !== ""
      ? window.location.pathname.slice(1)
      : "welcome"
  );

  useEffect(() => {
    setCurrentPath(
      window.location.pathname.slice(1) !== ""
        ? window.location.pathname.slice(1)
        : "welcome"
    );
  }, [window.location.pathname]);

  useEffect(() => {
    setPageCall((pageCall) => ({
      ...pageCall,
      pageName: currentPath,
      trigger: true
    }));
  }, [currentPath]);

  return (
    <Router>
      <Navbar pageCall={pageCall} setPageCall={setPageCall} />
      <Routes>
        {allRoutes.map((e, i) => (
          <Route
            exact
            key={i}
            path={e.path}
            element={<e.component pageCall={pageCall} />}
          ></Route>
        ))}
      </Routes>
    </Router>
  );
}
