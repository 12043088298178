import { useEffect, useState } from "react";
import { Loading } from "../components/loading";
import { Outer } from "../elements";
import {
  FaReact,
  FaNodeJs,
  FaPaintBrush,
  AiFillHtml5,
  ImCss3,
  DiJavascript1,
  SiTypescript,
  SiRedux,
  SiNextdotjs,
  SiPhp,
  SiNginx,
  SiApache,
  SiMysql,
  SiPrisma,
  SiAndroidstudio,
  BsWordpress,
  BsFillCameraReelsFill,
  RiSoundModuleFill,
  MdCamera
} from "../assets/icons";
import { IconBox } from "../components/icon-box";

export const Skills = ({ pageCall }) => {
  const [data, setData] = useState({
    data: {},
    success: false
  });
  const iconsArray = [
    <FaReact />,
    <FaNodeJs />,
    <AiFillHtml5 />,
    <ImCss3 />,
    <DiJavascript1 />,
    <SiTypescript />,
    <SiRedux />,
    <SiNextdotjs />,
    <SiPhp />,
    <SiNginx />,
    <SiApache />,
    <SiMysql />,
    <SiPrisma />,
    <SiAndroidstudio />,
    <BsWordpress />,
    <BsFillCameraReelsFill />,
    <RiSoundModuleFill />,
    <MdCamera />,
    <FaPaintBrush />
  ];
  useEffect(() => {
    const resp = pageCall.resp.React;
    if (resp) {
      setData({
        resp: resp,
        success: true
      });
    }
  }, [pageCall.success]);
  return (
    <Outer wrap={"wrap"} direction={"row"}>
      {pageCall.success ? (
        iconsArray.map((e, i) => (
          <IconBox key={i} icon={e} title={data.resp?.[i]} />
        ))
      ) : (
        <Loading />
      )}
    </Outer>
  );
};
