import styled from "styled-components";

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: center;
  & * {
    margin: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
`;

export const Container = styled.div`
  margin: 0 1rem;
`;

export const Name = styled.h2``;

export const Title = styled.h3``;
