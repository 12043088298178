import { Container, Icon, Title } from "./elements";

export const LinksBox = ({ icon, title, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Icon> {icon} </Icon>
      <Title> {title} </Title>
    </Container>
  );
};
