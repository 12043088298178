import { useState, useEffect } from "react";
import { ContactForm } from "../components/contact-form";
import { Loading } from "../components/loading";
import { Inner, Outer } from "../elements";
import { postMessage } from "../functions/post-message";
import { url } from "../config";
import { EmailResponse } from "../components/email-response";
import {
  AiFillGithub,
  BsLinkedin,
  BsWordpress,
  FaReact
} from "../assets/icons";
import { LinksBox } from "../components/links-box";

export const Contact = ({ pageCall }) => {
  const [inputsData, setInputsData] = useState({
    email: "",
    subject: "",
    message: "",
    success: false
  });
  const [data, setData] = useState({ resp: "" });
  const { email, subject, message } = inputsData;
  const [messagePost, setMessagePost] = useState({
    resp: "",
    success: false,
    error: false,
    loading: false,
    trigger: false
  });

  useEffect(() => {
    const { send, gitHub, WordPress, React, Linkedin } = pageCall.resp;
    const passed = send && gitHub && WordPress && React && Linkedin;
    if (passed) {
      setData({
        resp: pageCall.resp,
        success: true
      });
    }
  }, [pageCall.success]);

  const { gitHub, WordPress, React, Linkedin } = data.resp;
  const linksArray = [gitHub, WordPress, React, Linkedin];
  const titles = linksArray.map((e) => e?.[0]);
  const links = linksArray.map((e) => e?.[1]);
  const icons = [
    <AiFillGithub />,
    <BsWordpress />,
    <FaReact />,
    <BsLinkedin />
  ];

  postMessage(messagePost, setMessagePost, inputsData, `${url}/mail`);
  const handlePostMessage = () => {
    const passed = email.length && subject.length && message.length;
    if (passed) {
      setMessagePost((messagePost) => ({ ...messagePost, trigger: true }));
    }
  };

  const closeResponse = () => {
    setMessagePost((messagePost) => ({ ...messagePost, success: false }));
    setInputsData({
      email: "",
      subject: "",
      message: "",
      success: false
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Outer>
      {data.success ? (
        <>
          {messagePost.success && (
            <EmailResponse
              messageId={messagePost.resp}
              closeClick={() => closeResponse()}
            />
          )}
          <Inner>
            {titles.map((e, i) => (
              <LinksBox
                key={i}
                title={e}
                icon={icons[i]}
                onClick={() => openInNewTab(links[i])}
              />
            ))}
          </Inner>
          <ContactForm
            inputsData={inputsData}
            setInputsData={setInputsData}
            sendButton={() => handlePostMessage()}
          />
        </>
      ) : (
        <Loading />
      )}
    </Outer>
  );
};
