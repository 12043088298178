import { Container, Table, Tbody, Td, TrBody } from "./elements";

export const Education = ({ edu }) => {
  return (
    <>
      <Container>
        <Table>
          <Tbody>
            <TrBody>
              {edu.map((o, i) => (
                <Td key={i}>{o[0]}</Td>
              ))}
            </TrBody>
            <TrBody>
              {edu.map((o, i) => (
                <Td key={i}>{o[1]}</Td>
              ))}
            </TrBody>
            <TrBody>
              {edu.map((o, i) => (
                <Td key={i}>{o[2]}</Td>
              ))}
            </TrBody>
            <TrBody>
              {edu.map((o, i) => (
                <Td key={i}>{o[3]}</Td>
              ))}
            </TrBody>
            <TrBody>
              {edu.map((o, i) => (
                <Td key={i}>{o[4]}</Td>
              ))}
            </TrBody>
          </Tbody>
        </Table>
      </Container>
    </>
  );
};
