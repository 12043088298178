import { Container, Image, Main, Name, Title } from "./elements";

export const ImageAndTitle = ({ img, name, title }) => {
  return (
    <Main>
      <Image src={img} alt={name || "alt"} />
      <Container>
        <Name> {name} </Name>
        <Title> {title} </Title>
      </Container>
    </Main>
  );
};
