import { Container, Form, Input, Textarea, Button } from "./elements";

export const ContactForm = ({
  inputsData,
  setInputsData,
  postMessage,
  setPostMessage,
  sendButton
}) => {
  const { from, subject, message } = inputsData;
  const onSubmit = (e) => {
    e.preventDefault();
    const passed = from && subject && message;
    if (passed) {
      setPostMessage((postMessage) => ({ ...postMessage, trigger: true }));
    }
  };

  const getInputData = (e, key) => {
    setInputsData((inputsData) => ({ ...inputsData, [key]: e.target.value }));
  };

  return (
    <Container>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Input
          onChange={(e) => getInputData(e, "email")}
          type="email"
          name="email"
          id="email"
          placeholder="your@email.address"
        />
        <Input
          onChange={(e) => getInputData(e, "subject")}
          type="text"
          name="subject"
          id="subject"
          placeholder="Message Subject"
        />
        <Textarea
          onChange={(e) => getInputData(e, "message")}
          name="message"
          id="message"
          placeholder="Your message here"
        />
        <Button onClick={sendButton}> send </Button>
      </Form>
    </Container>
  );
};
