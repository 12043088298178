import styled from "styled-components";

export const Outer = styled.div`
  display: flex;
  min-height: 93vh;
  flex-direction: ${(props) => props.direction || "column"};
  flex-wrap: ${(props) => props.wrap || "none"};
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
  font-size: 0.9rem;
  color: black;
  width: 100%;
  padding: 2rem;
  margin-top: 3.2rem;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  @media (min-width: 0px) {
    max-width: 23rem;
  }
  @media (min-width: 550px) {
    max-width: 33rem;
  }
  @media (min-width: 1100px) {
    max-width: 43rem;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
`;
