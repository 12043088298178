import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavContainerStyled = styled.div`
  display: flex;
  background: white;
  border: 1px solid grey;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  font-family: "Tahoma";
  font-size: 0.9rem;
  color: black;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media (min-width: 0px) {
    max-width: 23rem;
  }
  @media (min-width: 550px) {
    max-width: 33rem;
  }
  @media (min-width: 1100px) {
    max-width: 43rem;
  }
`;

export const NavLinkStyled = styled(Link)`
  text-decoration: none;
  display: flex;
  color: black;
  margin: auto;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:nth-child(${(props) => props.active}) {
    border-radius: 0.5rem;
    color: white;
    background: rgb(000, 000, 000, 0.2);
  }
  & span {
    position: relative;
    top: 0.4rem;
    font-size: 2.5rem;
  }
`;
