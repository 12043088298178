import { useEffect, useState } from "react";
import { Outer } from "../elements";
import { Loading } from "../components/loading";
import { Work } from "../components/experience/work";
import { Education } from "../components/experience/education";
import { MediaBox } from "../components/media-box";
import { FaSchool, TiBusinessCard } from "../assets/icons";

export const Experience = ({ pageCall }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    const { start, Sep, Feb, Mar, Oct, Jul, Jan, Aug, Apr } = pageCall.resp;
    const passed = Sep && Feb && Mar && Oct && Jul && Jan && Aug && Apr;
    if (passed) {
      setData({
        edu: [start, Sep, Feb, Mar, Oct],
        work: [start, Apr, Aug, Jan, Jul],
        success: true
      });
    }
  }, [pageCall.success]);

  const { work, edu } = data;
  return (
    <Outer>
      {data.success ? (
        <>
          <MediaBox
            title={"education"}
            paragraph={edu[1][5]}
            icon={<FaSchool />}
            iconOn={true}
          />
          <Education edu={edu} />
          <MediaBox
            title={"career"}
            paragraph={edu[1][6]}
            icon={<TiBusinessCard />}
            iconOn={true}
          />
          <Work edu={edu} work={work} />
        </>
      ) : (
        <Loading />
      )}
    </Outer>
  );
};
