import axios from "axios";
import { useEffect } from "react";

export const postMessage = (messagePost, setMessagePost, inputsData, url) => {
  const { email, subject, message } = inputsData;
  const handleCall = () => {
    setMessagePost((messagePost) => ({ ...messagePost, loading: true }));
    axios
      .post(url, {
        from: email,
        subject: subject,
        message: message
      })
      .then((resp) =>
        setMessagePost((messagePost) => ({
          ...messagePost,
          resp: resp.data,
          success: true,
          error: false
        }))
      )
      .catch((err) =>
        setMessagePost((messagePost) => ({
          ...messagePost,
          error: err,
          success: false
        }))
      );
  };

  useEffect(() => {
    if (messagePost.trigger) {
      handleCall();
    }
    return () =>
      setMessagePost((messagePost) => ({
        ...messagePost,
        trigger: false,
        loading: false
      }));
  }, [messagePost.trigger]);
};
