import { Container, TextBox, Title, IconBox, P, Icon } from "./elements";

export const MediaBox = ({ title, paragraph, icon, iconOn = true }) => {
  return (
    <>
      <Container iconOn={iconOn}>
        <TextBox>
          <Title> {title} </Title>
          <P> {paragraph} </P>
        </TextBox>
        {iconOn && (
          <IconBox>
            <Icon> {icon} </Icon>
          </IconBox>
        )}
      </Container>
    </>
  );
};
