import { ProgressOuter, ProgressInner, Title, Container } from "./elements";

export const SpokenLanguages = ({ english, german, arabic }) => {
  return (
    <Container>
      <>
        <Title> {english && english.slice(0, -1).join(": ")} </Title>
        <ProgressOuter>
          <ProgressInner color={"blue"} width={english && english[2]}>
            {" "}
          </ProgressInner>
        </ProgressOuter>
      </>
      <>
        <Title> {arabic && arabic.slice(0, -1).join(": ")} </Title>
        <ProgressOuter>
          <ProgressInner color={"green"} width={arabic && arabic[2]}>
            {" "}
          </ProgressInner>
        </ProgressOuter>
      </>
      <>
        <Title> {german && german.slice(0, -1).join(": ")} </Title>
        <ProgressOuter>
          <ProgressInner color={"red"} width={german && german[2]}>
            {" "}
          </ProgressInner>
        </ProgressOuter>
      </>
    </Container>
  );
};
