import { CgCloseR, FcCheckmark } from "../../assets/icons";
import { Container, Card, Close, Icon, Paragraph } from "./elements";

export const EmailResponse = ({ messageId, closeClick }) => {
  return (
    <Container>
      <Card>
        <Close onClick={closeClick}>
          <CgCloseR />
        </Close>
        <Icon>
          <FcCheckmark />
        </Icon>
        <Paragraph>
          your message has been sent! thanks for contacting me :) I will reply
          as soon as possible. your message id is " {messageId} "
        </Paragraph>
      </Card>
    </Container>
  );
};
