import styled from "styled-components";

export const Container = styled.div``;
export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;
export const Input = styled.input`
  height: 2rem;
  font-size: 1rem;
  border-radius: 14px;
`;
export const Textarea = styled.textarea`
  min-height: 10rem;
  font-size: 1rem;
  border-radius: 14px;
`;
export const Button = styled.button`
  height: 3rem;
  font-size: 1rem;
  border-radius: 14px;
`;
