import { FaSchool, FaReact, FaNodeJs, FaPaintBrush } from "react-icons/fa";
import { TiBusinessCard } from "react-icons/ti";
import { AiFillHtml5, AiFillGithub } from "react-icons/ai";
import { ImCss3 } from "react-icons/im";
import { DiJavascript1 } from "react-icons/di";
import {
  SiTypescript,
  SiRedux,
  SiNextdotjs,
  SiPhp,
  SiNginx,
  SiApache,
  SiMysql,
  SiPrisma,
  SiAndroidstudio
} from "react-icons/si";
import { BsWordpress, BsFillCameraReelsFill, BsLinkedin } from "react-icons/bs";
import { RiSoundModuleFill } from "react-icons/ri";
import { MdCamera } from "react-icons/md";
import { CgCloseR } from "react-icons/cg";
import {
  FcCheckmark,
  FcPositiveDynamic,
  FcGraduationCap,
  FcHome,
  FcInfo
} from "react-icons/fc";

export {
  FaSchool,
  TiBusinessCard,
  FaReact,
  FaNodeJs,
  FaPaintBrush,
  AiFillHtml5,
  ImCss3,
  DiJavascript1,
  SiTypescript,
  SiRedux,
  SiNextdotjs,
  SiPhp,
  SiNginx,
  SiApache,
  SiMysql,
  SiPrisma,
  SiAndroidstudio,
  BsWordpress,
  BsFillCameraReelsFill,
  RiSoundModuleFill,
  MdCamera,
  FcPositiveDynamic,
  CgCloseR,
  FcCheckmark,
  AiFillGithub,
  BsLinkedin,
  FcGraduationCap,
  FcHome,
  FcInfo
};
