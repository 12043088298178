import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: scroll;
  padding-bottom: 1rem;
  border-radius: 14px;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const H2 = styled.h2`
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
`;

export const P = styled.p`
  margin: 0;
  text-align: justify;
  margin-bottom: 1rem;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export const TrBody = styled.tr`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  & :first-child {
    font-weight: bold;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  width: 100%;
`;

export const Tbody = styled.tbody`
  display: flex;
`;

export const Td = styled.td`
  padding: .5rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: left;
  background-color: rgba(255, 255, 255, 0.6);
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
x`;
