import styled from "styled-components";

export const Container = styled.div`
  margin: 4rem 0;
`;
export const Title = styled.h4``;
export const ProgressOuter = styled.div`
  background-color: #ccc;
`;

export const ProgressInner = styled.div`
  height: 3px;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color};
`;
