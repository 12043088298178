import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 6rem;
  align-items: center;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0.1rem;
`;
export const Icon = styled.i`
  font-size: 4rem;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  display: grid;
  max-width: fit-content;
  padding: 1rem;
  border-radius: 100%;
  color: white;
`;
export const Title = styled.h2`
  font-size: 1rem;
`;
