import { Container, Icon, Title } from "./elements";

export const IconBox = ({ icon, title }) => {
  return (
    <Container>
      <Icon> {icon} </Icon>
      <Title> {title} </Title>
    </Container>
  );
};
