import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 3.6rem;
  bottom: 0;
  background: rgb(255, 255, 255, 0.9);
  height: 35rem;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 0px) {
    max-width: 23rem;
  }
  @media (min-width: 550px) {
    max-width: 33rem;
  }
  @media (min-width: 1100px) {
    max-width: 43rem;
  }
`;
export const Close = styled.i`
  font-size: 2rem;
  position: absolute;
  right: 2rem;
  top: 1rem;
`;
export const Icon = styled.i`
  font-size: 5rem;
`;
export const Paragraph = styled.p`
  padding: 2rem;
`;
