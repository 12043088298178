import axios from "axios";
import { useEffect } from "react";

export const getPage = (pageCall, setPageCall, url) => {
  const arrayToObject = (param) => {
    return param.reduce((a, v) => ({ ...a, [v[0].split(" ")[0]]: v }), {});
  };
  const handleCall = () => {
    setPageCall((pageCall) => ({ ...pageCall, loading: true }));
    axios
      .get(url, {
        headers: {
          name: pageCall.pageName
        }
      })
      .then((resp) =>
        setPageCall((pageCall) => ({
          ...pageCall,
          resp: arrayToObject(resp.data.values),
          success: true,
          error: false
        }))
      )
      .catch((err) =>
        setPageCall((pageCall) => ({
          ...pageCall,
          error: err,
          sucess: false
        }))
      );
  };
  useEffect(() => {
    if (pageCall.trigger) {
      handleCall();
    }
    return () =>
      setPageCall((pageCall) => ({
        ...pageCall,
        trigger: false,
        loading: false,
        nameChanged: false
      }));
  }, [pageCall.trigger]);
};
